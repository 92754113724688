import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FeedCardComponent} from './feed-card/feed-card.component';
import {WelcomeCardComponent} from './feed-card/welcome-card.component';
import { FeedComponent } from './feed.component';
import {TrainingDataCardComponent} from './feed-card/training-data-card.component';
import {LensCardComponent} from './feed-card/lens-card.component';
import {NgbProgressbarModule} from '@ng-bootstrap/ng-bootstrap';
import {SharedModule} from '@shared/shared.module';
import {FeedService} from './feed.service';
import { UnitAnalysisCardComponent } from './feed-card/unit-analysis-card.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { DeepLearningCardComponent } from './feed-card/deep-learning-card.component';
import { AnnotationCardComponent } from './feed-card/annotation-card.component';
import { ChangelogCardComponent } from './feed-card/changelog-card.component';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    NgbProgressbarModule,
    FontAwesomeModule
  ],
  providers: [
    FeedService
  ],
  declarations: [
    AnnotationCardComponent,
    FeedCardComponent,
    DeepLearningCardComponent,
    LensCardComponent,
    TrainingDataCardComponent,
    UnitAnalysisCardComponent,
    WelcomeCardComponent,
    ChangelogCardComponent,
    FeedComponent,
  ],
  exports: [
    FeedComponent
  ]
})
export class FeedModule {
}
