<div class="feed">
  <div class="feed-h feed-h1">Your Recent Activity</div>
  <!-- <app-feed-changelog-card></app-feed-changelog-card> -->
  <div class="d-flex flex-column">
    <ng-container *ngFor="let group of cardDateGroup$ | async; trackBy: dateGroupTrackBy">
      <div class="feed-h feed-date d-flex flex-row align-items-baseline">
        <div class="date">{{group.date | date : 'd MMMM yyyy'}}</div>
        <div class="hr flex-grow"></div>
      </div>
      <ng-container *ngFor="let card of group.cards; trackBy: cardTrackBy">
        <ng-container [ngSwitch]="card.type">
          <app-feed-training-data-card *ngSwitchCase="feedCardType.TRAINING_DATA" [config]="card"></app-feed-training-data-card>
          <app-feed-annotation-card *ngSwitchCase="feedCardType.ANNOTATION" [config]="card"></app-feed-annotation-card>
          <app-feed-lens-card *ngSwitchCase="feedCardType.LENS" [config]="card"></app-feed-lens-card>
          <app-feed-dl-card *ngSwitchCase="feedCardType.DEEP_LEARNING" [config]="card"></app-feed-dl-card>
          <app-feed-unit-collection-card *ngSwitchCase="feedCardType.UNIT_ANALYSIS" [config]="card"></app-feed-unit-collection-card>
        </ng-container>
      </ng-container>
    </ng-container>
  <app-feed-welcome-card *ngIf="isReady"></app-feed-welcome-card>
  </div>
  <app-progress-balls *ngIf="!isReady"></app-progress-balls>
</div>