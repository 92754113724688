import {IViewBy} from '@shared/top-filter/iviewby.interface';

export const STOPWORD_VIEW_OPTIONS: IViewBy[] = [
  {
    key: 'updatedAt',
    label: 'Most Recent',
    order: 'desc',
    selectedByDefault: true,
    isFilter: false
  },
  {
    key: 'name',
    label: 'A-Z',
    order: 'desc',
    isFilter: false
  },
  {
    key: 'name',
    label: 'Z-A',
    order: 'asc',
    isFilter: false
  },
  {
    key: 'separator',
    label: '──────────',
    order: 'desc',
    disabled: true,
    isFilter: false
  },
  {
    key: 'isSoftDeleted',
    label: 'Hidden',
    order: 'desc',
    isFilter: true
  }
];
