import {IViewBy} from '@shared/top-filter/iviewby.interface';

export const SELECT_FILTER_OPTIONS: IViewBy[] = [
  {
    key: 'createdAt',
    label: 'Most Recent',
    order: 'desc',
    selectedByDefault: true,
    isFilter: false
  },
  {
    key: 'mutler.size',
    label: 'Size: Low to High',
    order: 'asc',
    isFilter: false
  },
  {
    key: 'mutler.size',
    label: 'Size: High to Low',
    order: 'desc',
    isFilter: false
  },
  {
    key: 'separator',
    label: '──────────',
    order: 'desc',
    disabled: true,
    isFilter: false
  },
  {
    key: 'isSoftDeleted',
    label: 'Hidden',
    order: 'desc',
    isFilter: true
  }
];
