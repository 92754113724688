import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';
import {IStopWords} from '../types/stop-words';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {debounceTime, take} from 'rxjs/operators';
import {TabSelector} from '@shared/half-tabs/half-tabs.component';
import {UtilsService} from '../utils/utils.service';
import {Subscription} from 'rxjs/internal/Subscription';
import {Subject} from 'rxjs/internal/Subject';
import {LensService} from '../services-http/lens.service';
import {BaseService} from '../services-http/base.service';
import {GlobalStopWordService} from '../services-http/global-stop-word.service';
import {KeycloakService} from 'keycloak-angular';
import {ILens} from '../types/lens';

@Component({
  selector: 'app-stop-word-editor',
  templateUrl: './stop-word-editor.component.html',
  styleUrls: ['./stop-word-editor.component.scss']
})
export class StopWordEditorComponent implements OnInit, OnChanges, OnDestroy {

  @Input() dtoStopWords: IStopWords;
  @Input() lens: ILens;
  @Input() embedded: boolean = false;

  // @Output() onClickRetrain: EventEmitter<any> = new EventEmitter();
  @Output() onListUpdate: EventEmitter<IStopWords> = new EventEmitter<IStopWords>();

  // form
  public inputNewWord: string = '';
  public dupName: boolean = false;

  public removedRelatives: {word, relative}[] = [];
  public legend: string[] = ['0-9', 'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R',
                              'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'];
  public tabs: TabSelector[];
  public wordClass: string;
  public renderWords: string[];

  private _writer: Subject<IStopWords> = new Subject<IStopWords>();
  private _subscriptions: Subscription[] = [];
  private _service: BaseService<IStopWords>;

  public isOwned: boolean = false;

  private _selectedWordHash: { [key: string]: boolean; } = {};

  constructor(public modalService: NgbModal,
              public lensService: LensService,
              public globalStopwordService: GlobalStopWordService,
              public kc: KeycloakService) {
    this.tabs = [];
    this.tabs.push(new TabSelector(), new TabSelector(), new TabSelector());
    this.tabs[0].text = UtilsService.TitleCase(TabContent.ALL);
    this.tabs[1].text =  UtilsService.TitleCase(TabContent.CHANGED);
    this.tabs[2].text =  UtilsService.TitleCase(TabContent.PRIOR);
  }

  ngOnInit() {
    if (!this.embedded) {
      this._service = this.globalStopwordService;
    }

    this.onTabChanged(this.tabs[0]);

    this._subscriptions.push(
      this._writer.pipe(debounceTime(333))
        .subscribe((dto: IStopWords) => {
          if (this._service) { // if embedded in the MTW, then use the ModifiersService to update the lens
            this._service.update(dto, () => {
              this.onListUpdate.emit(dto);
            });
          } else {
            this.onListUpdate.emit(dto);
          }
    }));
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.kc.loadUserProfile().then(profile => {
      console.log('kc profile', profile);
      console.log('dto.uid', this.dtoStopWords.uid);
      this.isOwned = profile.email === this.dtoStopWords.createdBy;
    });

    if (this.dtoStopWords && Object.isFrozen(this.dtoStopWords)) {
      this.dtoStopWords = Object.assign({}, this.dtoStopWords);
      if (this.dtoStopWords.words && Object.isFrozen(this.dtoStopWords.words)) {
        this.dtoStopWords.words = this.dtoStopWords.words.slice();
      }
      if (this.dtoStopWords.changeWords && Object.isFrozen(this.dtoStopWords.changeWords)) {
        this.dtoStopWords.changeWords = Object.assign({}, this.dtoStopWords.changeWords);
      }
    }

    this._selectedWordHash = {};
    this.lens.model.suggestedStopWords.forEach(key => this._selectedWordHash[key] = true);
  }

  isSuggestedWord(word: string) {
    return !!this._selectedWordHash[word];
  }

  ngOnDestroy(): void {
    this._subscriptions.forEach(sub => sub.unsubscribe() );
  }

  onTabChanged(tab: TabSelector): void {

    const appliedWords: string[] = this.getAppliedWords();

    const all: string[] = this.deduplicate(this.getState().all.concat(appliedWords)).sort();
    const added: string[] = Object.keys(this.dtoStopWords.changeWords).sort();
    const prior: string[] = appliedWords.sort();

    this.tabs[0].subtext = all.length;
    this.tabs[1].subtext = added.length;
    this.tabs[2].subtext = prior.length;

    switch (tab.text.toLowerCase()) {
      case TabContent.ALL:
        this.renderWords = all;
        this.wordClass = 'all';
        break;

      case TabContent.CHANGED:
        this.renderWords = added;
        this.wordClass = 'changed';
        break;

      case TabContent.PRIOR:
        this.renderWords = prior;
        this.wordClass = 'old';
        break;

      default:
        throw new Error('Tab Selector Not Found!');
        break;
    }
  }

  gotoLetter(letter: string): void {
    console.log('find letter', letter);
    const haystack: NodeListOf<Element> = document.querySelectorAll('[class^=aWord]');
    let needle: Element;
    haystack.forEach(element => {
      const candidate: string = element.textContent.trim().substr(0, 1).toLowerCase();
      const target: string = letter.toLowerCase().substr(0, 1);
      console.log('combo ?', candidate, target);
      if (candidate === target && !needle) {
        needle = element;
      }
    });

    if (needle) {
      needle.scrollIntoView();
    } else {
      console.log('no word with that letter');
    }
  }

  getSelectedIndex(): number {
    return this.tabs.indexOf(this.tabs.find(tab => tab.selected));
  }

  onClose() {
    this.modalService.dismissAll('onClose');
  }

  getState(): {added: string[], removed: string[], all: string[]} {
    return {
      added:  Object.keys(this.dtoStopWords.changeWords).filter(key => this.dtoStopWords.changeWords[key] === true),
      removed: Object.keys(this.dtoStopWords.changeWords).filter(key => this.dtoStopWords.changeWords[key] === false),
      all: Object.keys(this.dtoStopWords.changeWords)
    };
  }

  isChangedWord(word: string): boolean {
    return !!this.dtoStopWords.changeWords[word];
  }

  isWordAlreadyApplied(word: string): boolean {
    const appliedWords: string[] = this.getAppliedWords();
    const match: string = appliedWords.find(appliedWord => appliedWord === word);
    return !match;
  }

  onToggleWord(value: string) {
    // start with false
    value = value.toLowerCase();
    const alreadyInList: boolean = this.isChangedWord(value);

    if (alreadyInList) {
      delete this.dtoStopWords.changeWords[value];
    } else {
      this.dtoStopWords.changeWords[value] = false;
    }

    this.write();
    this.onTabChanged(this.tabs[this.getSelectedIndex()]);
  }

  getAppliedWords(): string[] {
    // this component is used in 2 contexts
    // it manages stopWordChanges
    // it manges globalStopWords

    // Todo: these 2 really need to be decoupled into their own components

    if (this.embedded === false) {
      return this.dtoStopWords.words;
    }

    const found: IStopWords = this.lens.rootStopWords.find(wordList => wordList.uuid === this.dtoStopWords.uuid);
    if (found) {
      let foundWords = found.words;
      if (Object.isFrozen(found.words)) {
        foundWords = foundWords.slice();
      }
      return foundWords;
    } else {
      return [];
    }
  }

  onKeydown(event) {
    if (event.key === 'Enter') {
      console.log(event, this.inputNewWord);

      if (this.inputNewWord.toLowerCase().trim().length === 0) {
        return;
      }

      // exists in changewords
      const changes = this.getState().all;
      if ( !!changes.find(word => word.toLowerCase() === this.inputNewWord.toLocaleLowerCase() )) {
        return;
      }

      const appliedWords: string[] = this.getAppliedWords();
      if ( !!appliedWords.find(word => word.toLowerCase() === this.inputNewWord.toLocaleLowerCase() )) {
        return;
      }

      this.dtoStopWords.changeWords[this.inputNewWord.toLowerCase()] = true;
      this.inputNewWord = '';
      this.write();
      this.onTabChanged(this.tabs[this.getSelectedIndex()]);
    }
  }

  onNameChange(): void {
    this._service.elements.pipe(
      take(1)
    ).subscribe((lists: IStopWords[]) => {
      this.dupName = !!lists.find(list => list.name === this.dtoStopWords.name && list.id !== this.dtoStopWords.id);
      this.write();
    });
  }

  write(): void {
    console.log('added', this.getState().added);
    console.log('removed', this.getState().removed);
    this._writer.next(this.dtoStopWords);
  }

  deduplicate(strings: string[]): string[] {
    const hash: any = {};
    strings.forEach(string => hash[string] = true);
    return Object.keys(hash);
  }
}

export class TabContent {
  static ALL: string = 'all';
  static CHANGED: string = 'newly edited';
  static PRIOR: string = 'applied to model';
}
